@import url("https://fonts.googleapis.com/css2?family=Zen+Maru+Gothic&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Orbitron:wght@400..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=M+PLUS+2:wght@100..900&family=Orbitron:wght@400..900&display=swap");

.App {
  display: flex;
  flex-direction: column;
  text-align: center;
  background-color: #efebe2;
  align-items: center;
}

p {
  font-family: "M PLUS 2", sans-serif;
  color: #333;
  line-height: 1.3;
  margin: 0px;
  font-size: 1em;
}

.title-area {
  font-family: "Zen Maru Gothic", "M PLUS 2", sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 10px;

  h1 {
    font-family: "Orbitron", sans-serif;
    font-size: 2em;
    font-weight: 700;
    color: rgb(150, 73, 0);
    padding: 10px;
  }
}

.content-area {
  display: flex;
  justify-content: flex-start;
  text-align: left;
  flex-direction: column;
  max-width: 1024px;
  padding: 10px;

  h2 {
    font-family: "Orbitron", sans-serif;
    font-size: 1.5em;
    font-weight: 700;
    color: rgb(150, 73, 0);
    margin-bottom: 0px;
  }

  h2::after {
    content: "";
    display: block;
    width: 100%;
    height: 2px;
    background-color: rgb(150, 73, 0);
    margin-top: 0px;
    margin-bottom: 10px;
  }
}
