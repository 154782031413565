@import url("https://fonts.googleapis.com/css2?family=Zen+Maru+Gothic&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Orbitron:wght@400..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=M+PLUS+2:wght@100..900&family=Orbitron:wght@400..900&display=swap");

.App {
  text-align: center;
  padding: 10px;
}

p {
  font-family: "M PLUS 2", sans-serif;
  font-size: 1.5em;
  color: #333;
  line-height: 1.3;
  margin: 0px;
}

.title-area {
  font-family: "Zen Maru Gothic", sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 10px;
  background-color: #efebe2;

  .title {
    font-family: "Orbitron", sans-serif;
    font-size: 4em;
    font-weight: 900;
    color: #333;
    margin: 0px;
  }

  .sub-title {
    font-size: 1.2em;
    font-weight: 700;
    color: #333;
    margin-bottom: -10px;
  }

  .title-image {
    object-fit: contain;
    max-width: 480px;
    @media screen and (max-width: 562px) {
      max-width: 90%;
    }
    margin-top: -40px;
  }
}

.language-area {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.image-wrapper {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  img {
    object-fit: contain;
    height: 60px;
    width: 150px;
    margin: 10px;
  }
}

.section-title-wrapper {
  display: flex;
  flex-direction: row;
  margin: 10px;
  align-items: center;

  img {
    object-fit: contain;
    width: 90px;
    margin-right: 20px;
  }

  p {
    font-weight: 700;
  }

  div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .section-title-sub {
    font-size: 1em;
    margin: 0px;
  }
  p {
    text-align: left;
  }

  .title {
    font-size: 26px;
  }
}

.section-image {
  object-fit: contain;
  max-width: 480px;
  @media screen and (max-width: 562px) {
    max-width: 80%;
  }
}

.red-paper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  width: 100%;
  background-color: #efe2e2;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
}

.paper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  width: 100%;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
}

#color-cream {
  background-color: #efebe2;
}

#color-red {
  background-color: #efe2e2;
}

#color-blue {
  background-color: #e2eaef;
}

#color-green {
  background-color: #e5efe2;
}

.description-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  padding: 10px;
  max-width: 480px;
  @media screen and (max-width: 562px) {
    max-width: 90%;
  }

  span {
    font-size: 0.7em;
    vertical-align: top;
  }
}

.annotation-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  padding: 10px;
  font-size: 0.7em;

  max-width: 480px;
  @media screen and (max-width: 562px) {
    max-width: 90%;
  }
}

a {
  color: #1f6dff;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.tosppWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
  width: 100%;
}

.google-playbanner-image {
  object-fit: contain;
  width: 200px;
  margin-bottom: 10px;
  cursor: pointer;
}

.iphone-text {
  margin-bottom: 20px;
}
